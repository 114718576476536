<template>
  <div
    v-if="link && linkText"
    ref="floater"
    class="pointer-events-none fixed bottom-4 right-4 z-40 md:bottom-12 md:right-12"
  >
    <div class="relative flex items-center justify-center">
      <svg
        ref="circleText"
        viewBox="0 0 100 100"
        class="w-24 lg:w-36"
        aria-hidden="true"
      >
        <defs>
          <path
            id="circle"
            d="M 50, 50 m -37, 0 a 37,37 0 1,1 74,0a 37,37 0 1,1 -74,0"
          />
        </defs>
        <text font-size="11">
          <textPath xlink:href="#circle" class="font-bold uppercase">
            {{ linkText }}
          </textPath>
        </text>
      </svg>

      <NuxtLink
        :to="link"
        class="group pointer-events-auto absolute flex h-12 w-12 items-center justify-center overflow-hidden rounded-full border-2 border-black text-black outline-none duration-300 before:absolute before:inset-y-0 before:left-0 before:w-full before:-translate-x-full before:rounded-none before:bg-black before:transition-transform hover:text-white before:hover:translate-x-0 focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-4 lg:h-14 lg:w-14"
        :aria-label="linkText"
      >
        <IconArrow
          class="relative h-3 w-3 transition-transform duration-300 sm:h-4 sm:w-4"
        />
      </NuxtLink>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  link: {
    type: String,
    default: null,
  },
  linkText: {
    type: String,
    default: null,
  },
})

const floater = ref(null)
const circleText = ref(null)

onMounted(() => {
  if (!props.link || !props.linkText) {
    return
  }

  if (!circleText.value || !floater.value) {
    return
  }

  const { $gsap } = useNuxtApp()
  const mm = $gsap.matchMedia(floater.value)
  const breakpoint = 1024

  mm.add(`(max-width: ${breakpoint - 1}px)`, async () => {
    await nextTick()

    $gsap.to(floater.value, {
      scrollTrigger: {
        trigger: floater.value,
        start: '25% top',
        end: 'bottom bottom',
        toggleActions: 'play none none reverse',
      },
      yPercent: 100,
      opacity: 0,
      ease: 'back.in(1.8)',
      duration: 0.3,
    })
  })

  // gsap rotate animation based on scroll position
  $gsap.to(circleText.value, {
    scrollTrigger: {
      trigger: 'body',
      start: 'top top',
      end: '+=10000',
      scrub: true,
    },
    rotation: 360 * 5,
    duration: 1,
    ease: 'none',
  })
})
</script>
